import React, { useEffect, useRef, useState } from "react"
import { Link } from "gatsby"
import { Container, Row, Col, Modal } from "react-bootstrap"
import $ from "jquery"
import { useLocation } from "@reach/router"
import {
  EmailShareButton,
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from "react-share"
import {
  EmailIcon,
  FacebookIcon,
  LinkedinIcon,
  TwitterIcon,
  XIcon,
  WhatsappIcon,
} from "react-share"
import "./newhomesStickyMenu.scss"
import EnquiryNow from "@Components/forms/enquiry-now"
import Lightbox from "react-image-lightbox"
import "react-image-lightbox/style.css"
import getVideoId from "get-video-id"
import {Newhomes_Properties} from '../../../../queries/common_use_query';
import PlayVideo from "../../../PlayVideo/PlayVideo"
// Sticky header
function getOffset(el) {
  let _x = 0
  let _y = 0
  while (el && !isNaN(el.offsetLeft) && !isNaN(el.offsetTop)) {
    _x += el.offsetLeft - el.scrollLeft
    _y += el.offsetTop - el.scrollTop
    el = el.offsetParent
  }
  return { top: _y, left: _x }
}
// Sticky header

const NewHomesStickyMenu = props => {


  const {loading, error, data} = Newhomes_Properties(props.propertyData.crm_id);

  const virtualTourId = props?.propertyData?.video_tour?.url && getVideoId(props.propertyData.video_tour.url);
  const virtualTourUrl = props?.propertyData?.video_tour?.url && props.propertyData.video_tour.url;

  const stickyRef = useRef()

  const [show, setShow] = useState(false)
  const [formType, setFormType] = useState("")
  const [videoid, setVideoId] = useState("")
  const [video_type, setVideoType] = useState("")
  const [isPlayvideo, setIsPlayVideo] = useState(false)
  const [fullscreen, setFullscreen] = useState(true)
  const handleClose = () => setShow(false)
  const handleShow = form_type => {
    setShow(true)
    setFormType(form_type)
  }

  const playVideoHandler = (video_url, video_type) => {
    setIsPlayVideo(true)
    setVideoId(video_url)
    setVideoType(video_type)
  }

  const handleShows = (video_url, video_type) => {
    setShow(true)
    setVideoId(video_url)
    setVideoType(video_type)
  }

  // Property details images lightbox
  //var propertyFloorPopup = []

  const [propertyFloorPopup, setPropertyFloorPopup] = useState([])

  const [photoIndex, setPhotoIndex] = useState(0)
  const [isOpen, setIsOpen] = useState(false)

  const openLighboxMobile = (e, ind, property_plans) => {
    e.preventDefault()
    setPhotoIndex(ind)
    setIsOpen(true)

    var propertyFloor = []

    property_plans?.length > 0 &&
      property_plans?.map(item => propertyFloor.push(item?.url))
    setPropertyFloorPopup(propertyFloor)
  }

  // Property details images lightbox

  useEffect(() => {
    // var propertySelector = ".newhomes-details-menu-wrapper li a"

    // $(propertySelector).on("click", function () {
    //   var get_id = $(this).attr("data-id")
    //   $(propertySelector).removeClass("active")
    //   $(this).addClass("active")

    //   if (get_id) {
    //     $("html, body").animate(
    //       {
    //         scrollTop: $("#" + get_id).offset().top - 140,
    //       },
    //       1000
    //     )
    //   }
    // })

    // $(".newhomesMap").click(function() {
    //     $('html, body').animate({
    //         scrollTop: $("#property-details-map").offset().top - 140
    //     }, 1000);
    // })

    window.addEventListener("scroll", windowScroll)
    return () => window.removeEventListener("scroll", windowScroll)
  }, [])

  // Property details sticky nav
  const windowScroll = () => {
    const top = getOffset(stickyRef.current).top + 100
    //const bottomSticky = getOffset(stickyRef.current).top + 2300;
    // const top = 650;
    // const bottomSticky = 1500;

    if (window.pageYOffset > top) {
      stickyRef.current.classList.add("is-sticked")
    } else {
      stickyRef.current.classList.remove("is-sticked")
    }
  }
  // Property details sticky nav

  // Social share
  const location = useLocation()

  const shareurl = typeof window !== "undefined" ? window.location.href : ""

  const [Shareicons, setShareicons] = React.useState(false)

  const openShareicons = () => {
    setShareicons(true)
    if (Shareicons === true) {
      setShareicons(false)
    }
  }

  const [modalBookViewformOpen, setBookViewingformOpen] = React.useState(false)

  const openBookViewformModal = () => {
    setBookViewingformOpen(true)
  }

  const trackerShare = event => {
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({
      event: "Share - social icons",
      formType: event + " - " + shareurl,
      formId: "Share",
      formName: "Share",
      formLabel: "Share",
    })
  }
  // Social share

  //console.log("propertyFloorPopup", propertyFloorPopup)

  const handleScroll=()=>{
    var propertySelector = ".newhomes-details-menu-wrapper li a"

    $(propertySelector).on("click", function () {
      var get_id = $(this).attr("data-id")
      $(propertySelector).removeClass("active")
      $(this).addClass("active")

      if (get_id) {
        $("html, body").animate(
          {
            scrollTop: $("#" + get_id).offset().top - 140,
          },
          100
        )
      }
    })
  }

  return (
    <React.Fragment>
      <div
        className="secondary-header sticky-header d-flex align-items-center"
        ref={stickyRef}
      >
        <Container>
          <div className="form-wrap">
            <Row>
              <Col lg={6} className="d-flex align-items-center">
                <ul className="list-inline newhomes-details-menu-wrapper">
                  <li className="list-inline-item">
                    <a
                      href="javascript:void(0)"
                      className="active newhomesDetails"
                      data-id="details_id"
                      onClick={()=>handleScroll()}

                    >
                      Details
                    </a>
                  </li>
                  <li className="list-inline-item">
                    <a
                      href="javascript:void(0)"
                      className="newhomesGallery"
                      data-id="gallery_id"
                      onClick={()=>handleScroll()}

                    >
                      Gallery
                    </a>
                  </li>
                  
                  {virtualTourUrl &&
                    (virtualTourId?.service === "youtube" ? (
                      <li className="list-inline-item">
                      <a
                        href="javascript:void(0)"
                        className="newhomesVideotour"
                        onClick={() =>
                          playVideoHandler(
                            virtualTourUrl,
                            "youtube"
                          )
                        }
                      >
                        Video Tour
                      </a></li>
                    ) : (
                      <li className="list-inline-item">
                        <a
                          href="javascript:void(0)"
                          className="newhomesVideotour"
                          onClick={() =>
                            handleShows(
                              virtualTourUrl,
                              "custom_video"
                            )
                          }
                        >
                          360 Tour
                        </a>
                      </li>
                      )
                    )
                  }

                  {props?.propertyData?.brochure?.length > 0 && 
                  <li className="list-inline-item">
                    <a
                      href={props?.propertyData?.brochure[0].url}
                      target="blank"
                      className="newhomesBrochure"
                      // onClick={e =>
                      //   openLighboxMobile(e, 0, props?.propertyData?.brochure)
                      // }
                    >
                      Brochure
                    </a>
                  </li>
                  }
                  {data?.properties?.length > 0 && 
                    <li className="list-inline-item">
                      <a
                        href="javascript:void(0)"
                        className="newhomesAvailability"
                        data-id="available_id"
                      onClick={()=>handleScroll()}

                      >
                        Availability
                      </a>
                    </li>
                  }

                  {props?.propertyData?.floorplan?.length > 0 && 
                  <li className="list-inline-item">
                    <a
                      href="javascript:void(0)"
                      className="newhomesSiteplan"
                      onClick={e =>
                        openLighboxMobile(e, 0, props?.propertyData?.floorplan)
                      }
                    >
                      Siteplan
                    </a>
                  </li>
                  }

                  {(props.propertyData?.latitude || props.propertyData?.longitude)&&
                  <li className="list-inline-item">
                    <a
                      href="javascript:void(0)"
                      className="newhomesMap"
                      id="newhomesMap"
                      data-id="property-details-map"
                      onClick={()=>handleScroll()}

                    >
                      Map
                    </a>
                  </li>
                    }
                </ul>
              </Col>
              <Col lg={6} className="d-lg-flex d-none justify-content-end">
                <ul className="list-inline newhomes-details-menu-enquire d-flex align-items-center">
                  
                  {props?.propertyData?.__typename!=="NewDevelopments"&&
                  <li className="list-inline-item border-line">
                    <a
                      href="javascript:void(0)"
                      className="d-flex align-items-center"
                    >
                      <i className="icon-new-homes-save"></i>{" "}
                      <span className="ms-2">Save</span>
                    </a>
                  </li>
                }
                  <li className="list-inline-item border-line">
                    <span className="social-share-wrapper newhomes-share">
                      <a
                        href="javascript:void(0)"
                        className="d-flex align-items-center"
                        onClick={openShareicons}
                      >
                        <i className="icon-new-homes-share"></i>{" "}
                        <span className="ms-2">Share</span>
                      </a>
                      {Shareicons && (
                        <div
                          onClick={openShareicons}
                          className="property-share-social-icons mobile-details-socila-share"
                        >
                          <FacebookShareButton
                            onClick={() => trackerShare("FacebookShareButton")}
                            url={shareurl}
                            className="my-share-button facebook-share"
                          >
                            <FacebookIcon
                              size={32}
                              round={false}
                              borderRadius={`10`}
                            />
                          </FacebookShareButton>
                          <TwitterShareButton
                            onClick={() => trackerShare("TwitterShareButton")}
                            url={shareurl}
                            className="my-share-button twitter-share"
                          >
                            <XIcon
                              size={32}
                              round={false}
                              borderRadius={`10`}
                            />
                          </TwitterShareButton>
                          <LinkedinShareButton
                            onClick={() => trackerShare("LinkedinShareButton")}
                            url={shareurl}
                            className="my-share-button linkedin-share"
                          >
                            <LinkedinIcon
                              size={32}
                              round={false}
                              borderRadius={`10`}
                            />
                          </LinkedinShareButton>
                          <WhatsappShareButton
                            onClick={() => trackerShare("WhatsappShareButton")}
                            url={shareurl}
                            className="my-share-button whatsapp-share"
                          >
                            <WhatsappIcon
                              size={32}
                              round={false}
                              borderRadius={`10`}
                            />
                          </WhatsappShareButton>
                          {/* <EmailShareButton url='#' onClick={()=> { trackerShare('SendToFriend')}} className="my-share-button email-share">
                                                        <EmailIcon size={32} round={false} borderRadius={`10`} />
                                                    </EmailShareButton> */}
                        </div>
                      )}
                    </span>
                  </li>
                  {props?.nego_info?.teams?.length > 0 && 
                  <li className="list-inline-item mobile-sticky">
                    <a
                      href={`tel:${props?.nego_info?.teams[0].Phone}`}
                      className="d-flex align-items-center"
                    >
                      <i className="icon-new-homes-call"></i>{" "}
                      <span className="ms-2">{props?.nego_info?.teams[0].Phone}</span>
                    </a>
                  </li>
                  }
                  <li className="list-inline-item mobile-sticky enquiry">
                    <button
                      className="btn update-btn"
                      onClick={() => handleShow("enquiry_now")}
                    >
                      Enquire Now
                    </button>
                  </li>
                </ul>
              </Col>
            </Row>
          </div>

          {isOpen && propertyFloorPopup.length != 0 && (
            <Lightbox
              wrapperClassName="light-box-image-zoom"
              mainSrc={propertyFloorPopup[photoIndex]}
              nextSrc={
                propertyFloorPopup[(photoIndex + 1) % propertyFloorPopup.length]
              }
              prevSrc={
                propertyFloorPopup[
                  (photoIndex + propertyFloorPopup.length - 1) %
                    propertyFloorPopup.length
                ]
              }
              onCloseRequest={() => setIsOpen(false)}
              onMovePrevRequest={() =>
                setPhotoIndex(
                  (photoIndex + propertyFloorPopup.length - 1) %
                    propertyFloorPopup.length
                )
              }
              onMoveNextRequest={() =>
                setPhotoIndex((photoIndex + 1) % propertyFloorPopup.length)
              }
            />
          )}
        </Container>
      </div>

      {video_type === "youtube" && (
        <PlayVideo
          isOpen={isPlayvideo}
          videoId={videoid}
          stopPlay={() => {
          setIsPlayVideo(false)
          setVideoType("")
          }}
        />
      )}

      {video_type === "custom_video" && (
        <Modal
          show={show}
          fullscreen={fullscreen}
          onHide={() => {
          setShow(false)
          setVideoType("")
          }}
          dialogClassName="modal-fullscreen property-details-modal"
        >
        <Modal.Header closeButton></Modal.Header>
          <Modal.Body className="p-0">
              <iframe src={videoid}></iframe>
            </Modal.Body>
          </Modal>
      )}

      {video_type !== "youtube" && video_type !== "custom_video" && (
        <Modal
          show={show}
          centered={true}
          onHide={handleClose}
          backdrop="static"
          keyboard={false}
          dialogClassName="modal-90w modal-form modal-form-buy-sell-rent reviewPopup"
        >
          <>
            {formType === "enquiry_now" && (
              <>
                <Modal.Header closeButton className="contact-close-btn">
                  <Modal.Title className="w-100 modal_ttl">
                    Enquire about this property
                  </Modal.Title>
                </Modal.Header>

                <Modal.Body className="form">
                  <EnquiryNow
                    propertyData={props.propertyData}
                    department={"New Homes"}
                    location={location}
                    nego_info={
                      props.nego_info?.teams.length > 0 &&
                      props.nego_info?.teams[0]
                    }
                    office_info={
                      props.office_info?.offices?.length > 0 &&
                      props.office_info?.offices[0]
                    }
                  />
                </Modal.Body>
              </>
            )}
          </>
        </Modal>
      )}
    </React.Fragment>
  )
}

export default NewHomesStickyMenu
