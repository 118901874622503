import React from 'react';
import { Container, Row, Col, Breadcrumb } from 'react-bootstrap';
import { useWindowSize } from '../../../hooks/window-size'; 
import BannerHeader from '../BannerHeader/BannerHeader';
import NewHomesPropertyBanner from './NewHomesPropertyBanner/NewHomesPropertyBanner';
import NewHomesStickyMenu from './NewHomesStickyMenu/NewHomesStickyMenu'
import NewHomesGallery from './NewHomesGallery/NewHomesGallery'
import NewHomesPropertyDescription from './NewHomesPropertyDescription/NewHomesPropertyDescription'
import PropertyInfo from './PropertyInfo/PropertyInfo';
import { People_Email, Office_ID } from "../../../queries/common_use_query"

import './propertySection.scss';

// Negotiator details
const NegoDetails = email_id => {
    const { loading, error, data } = People_Email(email_id)
  
    // console.log("nego_info ==>", data);
    return data
}
// Negotiator details

// Office Details
const OfficeDetails = office_id => {
    const { loading, error, data } = Office_ID(office_id)

    // console.log("office_info ==>", data);
    return data
}
// Office Details

const PropertySectionNewHomes = (props) => {
	const [windowWidth] = useWindowSize();

	// 
	let findUrl = "/new-homes-for-sale/in-ireland/"

	// 

	// Negotiator details
		var nego_info =
		props.propertyData?.crm_negotiator_id &&
		props.propertyData?.crm_negotiator_id?.Email &&
		NegoDetails(props.propertyData?.crm_negotiator_id?.Email)
	// Negotiator details

	// Office Details
		var office_info =
		props.propertyData?.office_crm_id &&
		OfficeDetails(props.propertyData?.office_crm_id)
	// Office Details

	return (
		<section className="property-details">
			{
				props?.propertyData &&
				<>
					<Container>
						<Row>
							<Col sm="12">
								{ windowWidth > 767 ? <Breadcrumb>
									<Breadcrumb.Item href="/">Home</Breadcrumb.Item>
									<Breadcrumb.Item href={findUrl}>{"new homes"}</Breadcrumb.Item>
									<Breadcrumb.Item active>{props?.propertyData.display_address}</Breadcrumb.Item>
								</Breadcrumb> : <BannerHeader /> }
							</Col>
						</Row>
					</Container>

					<NewHomesPropertyBanner propertyData={props.propertyData} />

					<NewHomesStickyMenu propertyData={props.propertyData} nego_info={nego_info} office_info={office_info} />

					<div id="details_id">				
						<NewHomesPropertyDescription propertyData={props.propertyData} nego_info={nego_info} />
					</div>
					
					{props.propertyData?.images?.length > 0 &&
					<div id="gallery_id"><NewHomesGallery propertyData={props.propertyData} /></div>
					}
					
				</>				
			}
		</section>
	)
}

export default PropertySectionNewHomes;
