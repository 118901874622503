import { Link } from "gatsby"
import React, { useState, useEffect, Fragment } from "react"
import { Container, Row, Col } from 'react-bootstrap';
import $ from "jquery";
import Slider from "react-slick";
import "./newHomesGallery.scss"
import ImageTransform from "@Components/common/ggfx-client/module/components/image-transform"
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';

const NewHomesGallery = (props) => {

    // Slider settings
    const settings = {
        dots: false,
        speed: 800,
        slidesToShow: 4,
        slidesToScroll: 1,
        infinite: false,
        arrows: true,
        mobileFirst: true,
        responsive: [
             {
            breakpoint: 9999,
            settings:"unslick"
            },
        
          {
            breakpoint: 767,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              infinite: true,
              dots:true,
            },
          },
          
        ],
    }
    // Slider settings

    let processedImages = JSON.stringify({})
    if (props?.propertyData?.imagetransforms?.images_Transforms) {
        processedImages = props?.propertyData?.imagetransforms.images_Transforms
    }

    // Property details images lightbox
    var propertyImagePopup = []
    
    const [photoIndex, setPhotoIndex] = useState(0);
    const [isOpen, setIsOpen] = useState(false);

    const openLighboxMobile = (e, ind) => {
        e.preventDefault();
        setPhotoIndex(ind);
        setIsOpen(true);
    } 

    props?.propertyData?.images && props?.propertyData?.images.map(item => propertyImagePopup.push(item.url))

    return (
        <React.Fragment>
            <Container className="new-homes-gallery-wrapper relatednews-block">
                <Row>
                    <Col lg={12}>
                        <h2>Image & Video Gallery</h2>
                    </Col>
                </Row>

                <Row>
                    <Col sm="12">
                        <Slider {...settings} className="new-homes-gallery">
                            {/* <div className="slider-container slider-banner-video property-banner">
                                <div className="img-gallery-box banner-right">
                                    <div className="video-wrap img-hover-zoom banner-right-top zoom">
                                        <picture>
                                            <source media="(min-width:992px)" srcSet={Gallery1} />
                                            <source media="(min-width:768px)" srcSet={Gallery1} />
                                            <img src={Gallery1} alt="" />
                                        </picture>
                                        <button type="button" className="btn-play">
											<i className="icon-play"></i>
										</button>
                                    </div>
                                </div>   
                            </div> */}
                            {props?.propertyData?.images?.length > 0 && props?.propertyData?.images?.map((item, index) => {

                                return(
                                    <div className="slider-container" onClick={(e) => openLighboxMobile(e,index)}>
                                        <div className="img-gallery-box">
                                            <div className="video-wrap img-hover-zoom">
                                                {/* <picture>
                                                    <source media="(min-width:992px)" srcSet={Gallery2} />
                                                    <source media="(min-width:768px)" srcSet={Gallery2} />
                                                    <img src={Gallery2} alt="" />
                                                </picture> */}
                                                <ImageTransform
                                                    imagesources={item.url}
                                                    renderer="srcSet"
                                                    imagename="new-developments.images.gallery_images"
                                                    attr={{
                                                        alt: props.propertyData.display_address,
                                                    }}
                                                    imagetransformresult={processedImages}
                                                    id={props.propertyData.id}
                                                ></ImageTransform>
                                            </div>
                                        </div>   
                                    </div>
                                )
                            })}
                            
                        </Slider>

                        {isOpen && propertyImagePopup.length != 0 && (
                            <Lightbox
                                wrapperClassName="light-box-image-zoom"
                                mainSrc={propertyImagePopup[photoIndex]}
                                nextSrc={
                                    propertyImagePopup[
                                        (photoIndex + 1) % propertyImagePopup.length
                                    ]
                                }
                                prevSrc={
                                    propertyImagePopup[
                                        (photoIndex + propertyImagePopup.length - 1) %
                                        propertyImagePopup.length
                                    ]
                                }
                                onCloseRequest={() => setIsOpen(false)}
                                onMovePrevRequest={() =>
                                    setPhotoIndex((photoIndex + propertyImagePopup.length - 1) % propertyImagePopup.length)
                                }
                                onMoveNextRequest={() =>
                                    setPhotoIndex((photoIndex + 1) % propertyImagePopup.length)
                                }
                            />
                        )}
                    </Col>
                </Row>
            </Container>
        </React.Fragment>
    )
}

export default NewHomesGallery