import { Link } from "gatsby"
import React, { useState, useEffect, Fragment } from "react"
import { Container, Row, Col } from "react-bootstrap"
import $ from "jquery"
import "./newhomesPropertyBanner.scss"
import PropertyImage from "../../../../images/new-homes-property.png"
import BannerLogo from "../../../../images/new-homes-property-banner-logo.png"
import NoImage from "../../../../images/no_image_new_homes.webp"
import ImageTransform from "@Components/common/ggfx-client/module/components/image-transform"

const NewHomesPropertyBanner = props => {
  //console.log("propertyData", props.propertyData);

  let processedImages = JSON.stringify({})
  if (props?.propertyData?.imagetransforms?.images_Transforms) {
    processedImages = props?.propertyData?.imagetransforms.images_Transforms
  }

  var developer_logo = props?.propertyData?.developer_logo?.url || props?.propertyData?.developer_logo2?.url

  return (
    <React.Fragment>
      <div className="static-banner newhomes-banner">
        {/* <picture>
                    <source media="(min-width: 992px)" srcSet={PropertyImage} />
                    <source media="(min-width: 768px)" srcSet={PropertyImage} />
                    <img src={PropertyImage} alt={""} className="banner-img" />
                </picture> */}
        {props?.propertyData?.images?.length > 0 ? 
        <ImageTransform
          imagesources={props?.propertyData?.images[0].url}
          renderer="srcSet"
          imagename="new-developments.images.details"
          attr={{
            alt: props.propertyData.display_address + " - DNG Estate Agents",
            className: "banner-img",
          }}
          imagetransformresult={processedImages}
          id={props.propertyData.id}
        ></ImageTransform>
        :
          <img src={NoImage} className="banner-img" alt={props.propertyData.display_address + " - DNG Estate Agents"} />
        }

        {developer_logo &&
        <Container className="banner-wrap">
          <Row>
            <Col sm="12">
              <div className="banner-content">
                <picture>
                  {/* <source media="(min-width: 992px)" srcSet={BannerLogo} /> 
                  <source media="(min-width: 768px)" srcSet={BannerLogo} /> */}
                  {/* <img src={BannerLogo} alt="Logo" className="banner-img" /> */}
                  <img
                    src={developer_logo}
                    alt={"Developer Logo - DNG Estate Agents"}
                    className="banner-img"
                  />
                </picture>
              </div>
            </Col>
          </Row>
        </Container>
}
      </div>
    </React.Fragment>
  )
}

export default NewHomesPropertyBanner
