import React, { useEffect, useRef, useState } from "react"
import { useQuery } from "@apollo/client"
import { Button, Form, Container, Row, Col, Fade, Modal } from "react-bootstrap"
import gql from "graphql-tag"
import { graphql } from "gatsby"
import $ from "jquery"
import { Link } from "gatsby"
import classNames from "classnames"
import Layout from "@Components/Layout/layout"
import Seo from "@Components/SEO/seo"
import PropertySectionNewHomes from "@Components/PropertyDetails/PropertySection/PropertySectionNewHomes"
import PropertyValuation from "@Components/SearchResults/Valuation/PropertyValuation"
import MapComponent from "@Components/PropertyDetails/PropertyMap/Map"
import PropertyDevelopment from "@Components/PropertyDetails/SimilarProperties/PropertyDevelopment"
import SimilarPropertiesNewHomes from "@Components/PropertyDetails/SimilarProperties/SimilarPropertiesNewHomes"
import BrandLogo from "../images/logo.svg"
import { BrowserRouter as Router, Route, Switch } from "react-router-dom"
import NotFoundPage from "../pages/404"
import PopularSearch from "../Components/StaticPageTwo/PopularSearches/PopularSearch"

// Sticky header
function getOffset(el) {
  let _x = 0
  let _y = 0
  while (el && !isNaN(el.offsetLeft) && !isNaN(el.offsetTop)) {
    _x += el.offsetLeft - el.scrollLeft
    _y += el.offsetTop - el.scrollTop
    el = el.offsetParent
  }
  return { top: _y, left: _x }
}
// Sticky header

const New_Developments = gql`
  query GetNewDevelopments($id: String!) {
    newDevelopments(where: { id: $id }) {
      id
      crm_id
      status
      search_type
      building
      price
      max_price
      price_qualifier
      bedroom
      display_address
      address
      title
      description
      slug
      images
      imagetransforms
      publish
      latitude
      longitude
      floorplan
      brochure
      video_tour
      extras
      floorarea_type
      floorarea_min
      crm_negotiator_id
      office_crm_id
      developer_logo {
        url
        alternativeText
      }
      developer_logo2
      developer
    }
  }
`

const PropertyDetailsNewHomesTemplate = props => {
  // Sticky header
  const stickyRef = useRef()
  //
  const pageurl = typeof window !== "undefined" ? window.location.href : ""
  let myid_filt = pageurl.split("?")
  let mypageurl = myid_filt[0]
  let property_slug = mypageurl.split("-")
  let id = property_slug[property_slug.length - 1]
  id = id.replace("/", "")

  const {loading, error, data: property_details} = useQuery(New_Developments, {
    variables: { id: id },
  })

  useEffect(() => {
    window.addEventListener("scroll", windowScroll)

    return () => window.removeEventListener("scroll", windowScroll)
  }, [])

  const windowScroll = () => {
    if (stickyRef.current?.classList) {
      const top = getOffset(stickyRef.current).top - 150
      if (window.pageYOffset > top) {
        stickyRef.current.classList.add("is-sticked")
      } else {
        stickyRef.current.classList.remove("is-sticked")
      }
    }
  }
  // Sticky header
  if(typeof window !== "undefined"&&window){
    var s = document?.getElementById("chat-widget-container")
    var t = document?.getElementById("livechat-eye-catcher")
    
    if(s){
      s.style.display = 'none'
    }
    if(t){
      t.style.display = 'none'
    }
  }
  // Loading logo
  if (loading)
    return (
      <section className={"loader-wrapper"}>
        <div id="loader-wrapper">
          <div id="loader" class="new-loader">
            <img
              className="logo-white loader-logo"
              src={BrandLogo}
              alt="logo"
            />
          </div>
        </div>
      </section>
    )
  // Loading logo
  //

  //console.log("latitude ==>", property_details?.newDevelopments )
  return (
    <Layout>
      {property_details &&
      property_details.newDevelopments &&
      property_details.newDevelopments.length > 0 ? (
        property_details.newDevelopments.map((data, i) => {
          var price = "€" + data?.price?.toLocaleString()

          var property_type = data?.building?.length > 0 ? data?.building?.join(", ") : ""

          var meta_title = `New ${data?.title} in ${data?.display_address} at ${price}`
          // if (data?.bedroom) {
          //   meta_title = `New ${property_type?.toLowerCase()} ${data?.status.toLowerCase()} with ${data?.bedroom
          //   } bedroom in ${data?.display_address} at ${price}`
          // }

          var meta_description = `Find the details of New $${data?.title} in ${data?.display_address} at ${price} with DNG Estate Agents. Request a viewing to get assistance in buying this new build ${property_type}.`

          // if (data?.bedroom) {
          //   meta_title = `Find the details of New ${property_type?.toLowerCase()} ${data?.status.toLowerCase()} with ${
          //     data?.bedroom
          //   } bedroom in ${
          //     data?.display_address
          //   } at ${price} with DNG Estate Agents. Request a viewing to get assistance in buying this new build ${property_type}.`
          // }

          var get_area = data?.address?.county
            ? data?.address?.county
            : data?.address?.town
            ? data?.address?.town
            : data?.address?.address4
            ? data?.address?.address4
            : data?.address?.address3
            ? data?.address?.address3
            : data?.address?.address2

          var searchParams = {
            areas: get_area.replace("Co. ", "").toLowerCase(),
          }

          const imageUrl = data?.images?.length > 0 ? data?.images[0]?.url : ""

          return (
            <React.Fragment>
              <Seo
                title={meta_title}
                description={meta_description}
                image={imageUrl ? imageUrl : null}
              />

              <div
                className={classNames("property-detail-content-main")}
                ref={stickyRef}
              >
                <PropertySectionNewHomes propertyData={data} tag="newhomes" />

                <div id="available_id">
                  <PropertyDevelopment propertyData={data} />
                </div>

                {data?.latitude || data?.longitude ? 
                  (
                  <div
                    className="property-details-landing-map"
                    id="property-details-map"
                    data-item="property-details-map"
                  >
                    <MapComponent property_details_map_data={data} />
                  </div>
                  ) : ""
                }
              </div>
              <PropertyValuation />
              <SimilarPropertiesNewHomes propertyData={data} />

              <section className="popular-search-result">
                <Container>
                  <Row>
                    <Col>
                      <PopularSearch
                        searchParams={searchParams}
                        department={"newdevelopments"}
                        SearchType={"Property_Details"}
                        status={data?.status}
                        areaName={searchParams.areas}
                      />
                    </Col>
                  </Row>
                </Container>
              </section>
            </React.Fragment>
          )
        })
      ) : (
        <Router>
          <Switch>
            <Route component={NotFoundPage} />
          </Switch>
        </Router>
      )}
    </Layout>
  )
}

export default PropertyDetailsNewHomesTemplate
